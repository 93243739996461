exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-fun-wave-playground-tsx": () => import("./../../../src/pages/fun/wave-playground.tsx" /* webpackChunkName: "component---src-pages-fun-wave-playground-tsx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-resume-tsx": () => import("./../../../src/pages/resume.tsx" /* webpackChunkName: "component---src-pages-resume-tsx" */),
  "component---src-templates-project-tsx-content-file-path-home-runner-work-jazevedo-me-jazevedo-me-content-projects-axis-replay-index-mdx": () => import("./../../../src/templates/Project.tsx?__contentFilePath=/home/runner/work/jazevedo.me/jazevedo.me/content/projects/axis-replay/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-home-runner-work-jazevedo-me-jazevedo-me-content-projects-axis-replay-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-home-runner-work-jazevedo-me-jazevedo-me-content-projects-blackjack-simulation-index-mdx": () => import("./../../../src/templates/Project.tsx?__contentFilePath=/home/runner/work/jazevedo.me/jazevedo.me/content/projects/blackjack-simulation/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-home-runner-work-jazevedo-me-jazevedo-me-content-projects-blackjack-simulation-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-home-runner-work-jazevedo-me-jazevedo-me-content-projects-brachistochrone-simulator-index-mdx": () => import("./../../../src/templates/Project.tsx?__contentFilePath=/home/runner/work/jazevedo.me/jazevedo.me/content/projects/brachistochrone-simulator/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-home-runner-work-jazevedo-me-jazevedo-me-content-projects-brachistochrone-simulator-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-home-runner-work-jazevedo-me-jazevedo-me-content-projects-college-explorer-docs-mdx": () => import("./../../../src/templates/Project.tsx?__contentFilePath=/home/runner/work/jazevedo.me/jazevedo.me/content/projects/college-explorer/docs.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-home-runner-work-jazevedo-me-jazevedo-me-content-projects-college-explorer-docs-mdx" */),
  "component---src-templates-project-tsx-content-file-path-home-runner-work-jazevedo-me-jazevedo-me-content-projects-college-explorer-index-mdx": () => import("./../../../src/templates/Project.tsx?__contentFilePath=/home/runner/work/jazevedo.me/jazevedo.me/content/projects/college-explorer/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-home-runner-work-jazevedo-me-jazevedo-me-content-projects-college-explorer-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-home-runner-work-jazevedo-me-jazevedo-me-content-projects-gamefest-website-index-mdx": () => import("./../../../src/templates/Project.tsx?__contentFilePath=/home/runner/work/jazevedo.me/jazevedo.me/content/projects/gamefest-website/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-home-runner-work-jazevedo-me-jazevedo-me-content-projects-gamefest-website-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-home-runner-work-jazevedo-me-jazevedo-me-content-projects-library-manager-index-mdx": () => import("./../../../src/templates/Project.tsx?__contentFilePath=/home/runner/work/jazevedo.me/jazevedo.me/content/projects/library-manager/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-home-runner-work-jazevedo-me-jazevedo-me-content-projects-library-manager-index-mdx" */),
  "component---src-templates-project-tsx-content-file-path-home-runner-work-jazevedo-me-jazevedo-me-content-projects-risk-game-index-mdx": () => import("./../../../src/templates/Project.tsx?__contentFilePath=/home/runner/work/jazevedo.me/jazevedo.me/content/projects/risk-game/index.mdx" /* webpackChunkName: "component---src-templates-project-tsx-content-file-path-home-runner-work-jazevedo-me-jazevedo-me-content-projects-risk-game-index-mdx" */)
}

